import "./main.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import playicons from "./playicon.svg";
import pauseicon from "./pause.svg";
import fullscreenicon from "./fullscreenIcon.svg";
import Loader from "../../utilities/Loader";
import configData from "../../vars.json";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  TextSearch,
  Annotation,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";
import { updateSampleSection } from "../sample-base";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import { MyContext } from "../../utilities/ContextData";
const baseURLPromo = configData.BASE_PORTAL_URL + "promo";

const Main = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { main, setMain, setMyHome } = useContext(MyContext);
  const videoRef = useRef(null);
  
  /*
  useEffect(() => {

    if (!main) {
      return navigate("/expired");
    }

  });

*/
  useEffect(() => {
    setMyHome(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);


/*
    const handleBeforeUnload = () => {
      console.log("Running unload");
      navigate("/expired");
    };
    // handles when page is unloaded
    window.addEventListener("beforeunload", handleBeforeUnload);
    // cleanup function handles when component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      handleBeforeUnload();
    };*/
  }, []);



  const handlePlay = () => {
    setIsPlaying(!isPlaying);
    if (!isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
    videoRef.current.pause();
  };
  const handleFullScreen = () => {
    videoRef.current.requestFullscreen();
  };
  React.useEffect(() => {
    updateSampleSection();
  }, []);
  const [numPages, setNumPages] = React.useState(null);
  const headers = {
    Authorization: "Hola Mundo",
  };

  // const [show, setShow] = React.useState(false)-no-;
  // const [mediaType, setmediaType] = React.useState(location.state.mediaType)-no-;

  const location = useLocation();

  var sender = location.state.sender;
  sender[0].toUpperCase();

  var sender_last = location.state.sender_last;
  sender_last[0].toUpperCase();

  var sended = location.state.sended;
  sended[0].toUpperCase();

  function capitalize(word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }

  var fileshow = "";

  switch (location.state.mediaType) {
    case "Img":
      var sectionStyle = {
        backgroundImage: "url(" + location.state.url + ")",
        width: "100%",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };

      break;
    case "Video":
      fileshow = (
        <>
          {" "}
          <video
            ref={videoRef}
            onEnded={handlePause}
            className="left-2/4 -translate-x-2/4 w-full h-full absolute top-0 z-[1] lg:object-cover"
          >
            <source src={location.state.url} type="video/mp4" />
            <source src="movie.ogg" type="video/ogg" />
          </video>
          <img
            src={isPlaying ? pauseicon : playicons}
            alt="Play Icon"
            onClick={handlePlay}
            className={`absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 cursor-pointer z-50 max-w-[60px] lg:max-w-[96px] ${isPlaying ? "opacity-0 group-hover:opacity-100" : "opacity-100"
              }`}
          />
          <img
            src={fullscreenicon}
            alt="Play Icon"
            onClick={handleFullScreen}
            className={`absolute top-5 right-5 lg:hidden cursor-pointer hover:scale-105 transition-transform z-50 w-5 ${isPlaying ? "opacity-0 group-hover:opacity-100" : "opacity-0"
              }`}
            title="Fullscreen"
          />
        </>
      );
      break;
    case "Documento":
      console.log("Es un PDF nuevo")
      fileshow = (
        <div className="pdfCont">
          <PdfViewerComponent
            id="container"
            documentPath={location.state.url}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
            style={{ height: "80vh" }}
          >
            <Inject
              services={[
                Toolbar,
                Magnification,
                Navigation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                Print,
                TextSelection,
                TextSearch,
                Annotation,
                FormFields,
                FormDesigner,
              ]}
            />
          </PdfViewerComponent>
        </div>
      );

      break;
    case "DocumentoWrite":
      fileshow = (
        <div className="pdfCont">
          <PdfViewerComponent
            id="container"
            documentPath={location.state.url}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/pdfviewer"
            style={{ height: "80vh" }}
          >
            <Inject
              services={[
                Toolbar,
                Magnification,
                Navigation,
                LinkAnnotation,
                BookmarkView,
                ThumbnailView,
                Print,
                TextSelection,
                TextSearch,
                Annotation,
                FormFields,
                FormDesigner,
              ]}
            />
          </PdfViewerComponent>
        </div>
      );

      break;
    case "Audio":
      fileshow = (
        <div className="audioP">
          <img
            className="max-w-[100px] mx-auto"
            alt="audio"
            src="/assets/img/volume2.png"
          />
          <ReactAudioPlayer
            src={location.state.url}
            autoPlay
            controls
            className=" mx-auto"
          />
        </div>
      );
      break;
  }

  var verHash = "https://polygonscan.com/tx/" + location.state.hash;
  var profile = <img src="assets/img/user.png" alt="" />;
  if (location.state.photo != "NO PHOTO") {
    var fullName = location.state.sender + " " + location.state.sender_last; // create a new variable called 'fullName'
    var initials = location.state.sender[0] + location.state.sender_last[0];
    profile = (
      <div className="circle bg-white border p-3 rounded-full max-w-[65px]">
        {" "}
        <p>{initials}</p>
      </div>
    );
  } else {
    profile = (
      <img
        src={location.state.photo}
        alt=""
        className="bg-white border max-w-[65px]"
      />
    );
  }
  var titleBox = <p></p>;
  if (location.state.title != "") {
    titleBox = (
      <div className="my-1 p-2 bg-white border border-pastBorder rounded" id="titlebox">
        <p className="text-sm font-bold text-gray-700" id="titlep">
          {/* title goes here */}
          {location.state.title}
        </p>
      </div>
    );
  } 

  var mensaje = <p></p>
  if (location.state.message != "") {
    mensaje =
      <div className="my-3 p-3 bg-white border border-pastBorder rounded" id="textbox">
        <p className="text-sm font-medium text-gray-400" id="textp">
          {location.state.message}
        </p>
      </div>
  }
  //reloading problem solved here......................................
  const navigate = useNavigate();
  /*
  useEffect(() => {
    const handleNavigationTiming = (navigationTiming) => {
      // Check if the page was navigated via a reload or refresh
      if (
        navigationTiming.type === "reload" ||
        navigationTiming.type === "back_forward"
      ) {
        // Navigate to the expired page
        setMain(false);
      //  navigate("/expired");
        // Push a new state onto the history stack to prevent the user from navigating back
      }
    };

    // Check if the Navigation Timing API is supported
    if (window.PerformanceNavigationTiming) {
      // Get the PerformanceNavigationTiming object
      const navigationTiming = performance.getEntriesByType("navigation")[0];

      // Call the handleNavigationTiming function with the PerformanceNavigationTiming object
      handleNavigationTiming(navigationTiming);
    }
  }, [navigate]);
  */

  if (isLoading) {
    return <Loader />;
  }
  return (
    <section className="grid grid-cols-4 bg-white">
      <div className="pt-5 col-span-4 lg:hidden">
        <Navbar telefono={location.state.id} />
      </div>
      <div className="min-h-[35vh] lg:min-h-screen relative group col-span-4  lg:col-span-2 xl:col-span-2 bg-gray-100">
        {/* showing files contents video/image  */}
        <div id="background" style={sectionStyle}></div>
        {fileshow}
        <div
          className={`z-40 absolute bottom-5 left-2/4 -translate-x-2/4 bg-white flex justify-start items-center space-x-2 lg:space-x-3 px-2 py-1  lg:p-3 mx-auto  w-max  md:max-w-[500px]   xl:max-w-[614px]  xl:w-[614px] rounded-xl ${isPlaying ? "opacity-0" : "opacity-100"
            }`}
        >
          <img
            src="assets/img/image 1.svg"
            alt=""
            className="max-w-[32px] lg:max-w-[42px]"
          />
          <p className="font-inter font-medium text-xs lg:text-base">
            Mensaje de {" "}
            <span className="capitalize">
              {location.state.sender} {location.state.sender_last}
            </span>
          </p>
        </div>
      </div>
      <div className="lg:pl-10 lg:pt-6  lg:pr-10 2xl:pl-28 2xl:pt-6  2xl:pr-20 col-span-4 lg:col-span-2 xl:col-span-2  ">
        <div className="hidden lg:block">
          {" "}
          <Navbar telefono={location.state.id} />
        </div>
        <div className="pt-5 pb-5 px-3 lg:pt-[102px]">
          <div className="bg-[#F8F8F8] mx-auto lg:mx-0  rounded-xl p-3 sm:p-5 md:pb-10 lg:pl-6 lg:py-9 max-w-[450px] md:max-w-[594px]">
            <div className="max-w-lg">
              <div className="flex items-center justify-start space-x-5">
                {profile}
                <div>
                  <h2 className="lg:text-2xl font-semibold text-headingBlack capitalize font-inter">
                    {/* User Name goes here */}
                    {location.state.sender} {location.state.sender_last}
                  </h2>
                  <h3 className="text-gray-400 text-xs lg:text-sm font-inter">
                    {/* programed date goes here */}
                    {location.state.programDate.split(",")[0]}
                  </h3>
                </div>
              </div>
              {titleBox}
              {mensaje}


              {/* respond button for future use  */}

              {/* <div className="flex justify-end mb-4">
            {" "}
            <button className="font-medium py-3 px-[56px] bg-prime text-white rounded-full font-inter inline hover:bg-prime/80">
              Respond
            </button>
          </div> */}
              {/*  <p className="font-medium font-inter text-xs lg:text-sm pt-3">
                Los psicólogos recomiendan: "En procesos de duelo deja un mensaje a esa persona que murió
                como una forma de seguir adelante y decirle lo que hubieses querido que supiera."
        </p>*/}
              <h2 className="lg:text-2xl font-semibold font-inter mt-4 mb-4 leading-5">
                Detalles del token (NFT)
              </h2>
              <div className="grid sm:grid-cols-2 grid-cols-1 border border-pastBorder bg-white rounded">
  <div className="py-3 px-3 md:pl-4 border-b border-b-pastBorder md:mr-4">
    <h3 className="font-inter text-sm sm:text-[15px]">
      Fecha
    </h3>
    <p className="font-inter text-xs text-[#3C3C4399]">
      {location.state.date}

    </p>
  </div>

  <div className="py-3 px-3 md:pr-4 border-b border-b-pastBorder md:ml-4">
    <h3 className="font-inter text-sm sm:text-[15px]">Contenido</h3>
    <p className="font-inter text-xs text-[#3C3C4399]">
      {location.state.mediaType}
    </p>
  </div>

  <div className="py-3 px-3 md:pl-4 border-b border-b-pastBorder md:mr-4">
    <h3 className="font-inter text-sm sm:text-[15px]">
      Blockchain
    </h3>
    <p className="font-inter text-xs text-[#3C3C4399]">Polygon</p>
  </div>

  <div className="py-3 px-3 md:pr-4 border-b border-b-pastBorder md:ml-4">
    <h3 className="font-inter text-sm sm:text-[15px]">Estandar del token</h3>
    <p className="font-inter text-xs text-[#3C3C4399]">
      ERC-721
    </p>
  </div>

  <div className="py-3 px-3 md:pl-4 border-b border-b-pastBorder md:mr-4 col-span-2">
    <h3 className="font-inter text-sm sm:text-[15px]">Hash</h3>
    <a
      href={verHash}
      target="_blank"
      rel="noreferrer"
      className="font-inter text-xs underline break-words text-[#1877F2] block w-full"
    >
      {location.state.hash}
    </a>
  </div>
</div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
