import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { registerLicense } from "@syncfusion/ej2-base";
import { Toaster } from "react-hot-toast";
import ContextData from "./utilities/ContextData";
registerLicense(
  "ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5VdE1iWn9adXFTRmlZ");
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
 <React.StrictMode>
    <ContextData>
    <App />
    <Toaster position="bottom-right" />
    </ContextData>
    </React.StrictMode>
);

reportWebVitals();
