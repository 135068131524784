import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <img
          src="assets/img/Bird.gif"
          alt="Loading..."
          className="loader-image"
        />
        <p className="loader-text">
          Un momento por favor
        </p>
      </div>
    </div>
  );
};

export default Loader;
