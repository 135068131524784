import { useState, useEffect, useRef } from "react";

function OtpInput({ numInputs = 6, onChange }) {
  const [inputs, setInputs] = useState(Array(numInputs).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handleChange = (index, event) => {
    const value = event.target.value;
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    if (value && index < numInputs - 1) {
      inputRefs.current[index + 1].focus();
    }

    // Run the onChange function only when the last digit is entered
    if (index === numInputs - 1 && value) {
      const otp = newInputs.join("");
      onChange(otp);
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !inputs[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className="flex space-x-[12px] ">
      {Array(numInputs)
        .fill()
        .map((_, index) => (
          <input
            key={index}
            ref={(ref) => (inputRefs.current[index] = ref)}
            className="inline-block w-9 h-9 sm:w-10 sm:h-10 md:w-14 md:h-14 lg:w-[70px] lg:h-[70px] border outline-none pl-2 py-2 md:pl-5 lg:pl-6 lg:py-4 text-xl lg:text-4xl font-semibold text-headingBlack rounded border-[#EBEAED]"
            inputMode="decimal"
            maxLength="1"
            pattern="[0-9]*"
            value={inputs[index]}
            onChange={(event) => handleChange(index, event)}
            onKeyDown={(event) => handleKeyDown(index, event)}
          />
        ))}
    </div>
  );
}

export default OtpInput;
