import React, { createContext, useState } from "react";
export const MyContext = createContext();
const ContextData = ({ children }) => {
  const [myHome, setMyHome] = useState(true);
  const [main, setMain] = useState(false);
  const values = {
    myHome,
    setMyHome,
    main,
    setMain,
  };
  return <MyContext.Provider value={values}>{children}</MyContext.Provider>;
};

export default ContextData;
