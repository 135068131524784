import React from "react";

const Button1 = ({ children, handleclick, style }) => {
  return (
    <button
      onClick={handleclick}
      className="font-medium py-2 px-3 text-xs sm:text-sm md:text-base md:py-3 md:px-[25px] bg-prime text-white rounded-full font-inter inline hover:bg-prime/80"
      style={style}
    >
      {children}
    </button>
  );
};

export default Button1;
