import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Worker } from "@react-pdf-viewer/core";
import Main from "./pages/Main/Main";
import Home from "./pages/Home/Home";
import Expired from "./pages/Expired/Expired";
function App() {
  return (
    <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.15.349/pdf.worker.min.js">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/message" element={<Main />} />
          <Route path="/expired" element={<Expired />} />
        </Routes>
      </BrowserRouter>
    </Worker>
  );
}

export default App;
