import React, { useState, useEffect } from "react";
import './TwoFactorAuth.css';  
import Button1 from "../../shared/Buttons/Button1";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../utilities/Loader";
import { toast } from "react-hot-toast";
import OtpInput from "./OtpInput";
import Col from 'react-bootstrap/Col';
import SmsIcon from '../../utilities/sms.svg';
import WhatsappIcon from '../../utilities/whatsapp.svg';
import CallIcon from '../../utilities/call.svg';

const TwoFactorAuth = ({ baseURL3, baseURL, baseURL2 }) => {
  const [post, setPost] = useState(null);
  const [sms, setSms] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [otpMethod, setOtpMethod] = useState("WhatsApp");
  const [timePassed, setTimePassed] = useState(0);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(0);
  const [codeSent, setCodeSent] = useState(false);
  const [showSend, setSend] = useState(true);
  const [chosenM, setChosenM] = useState("Whats");
  const navigate = useNavigate();
  const headers = {
    Authorization: "Hola Mundo",
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleOnClick = () => {
    alert("This message displays on page render");
  };

  function handleClick() {
    console.log("Button clicked");
  }

  function validate(value) {
    var code = value.toString();
    setIsLoading(true);
    axios.post(baseURL3, { id: query.get("id"), code: code }, { headers: headers })
      .then(async (response) => {
        if (response.data === "Wrong Password") {
          toast.error(
            <>
              <strong>Código incorrecto - por favor intantalo de nuevo</strong>
              <span role="img" aria-label="alert"></span>
            </>,
            {
              duration: 5000,
              style: {
                padding: '15px',
                color: "white",
                fontSize: '1.5rem',
                backgroundColor: "#fb8d8f",
              },
            }
          );
          setIsLoading(false);
        } else {
          await fetch(response.data.url).then((resi) => {
            resi.blob().then((blob) => {
              var file = new File([blob], "nombre.pdf", {
                type: "application/pdf",
              });
              getBase64(file)
                .then((result) => {
                  file["base64"] = result;
                  setIsLoading(false);
                  console.log(response);
                  console.log("resultado");
                  console.log("NUEVO codigo");
                  console.log(result);
                  var uri = "";
                  if (response.data.mediaType === "Documento" || response.data.mediaType === "DocumentoWrite") {
                    uri = result;
                  } else {
                    uri = response.data.url;
                  }
                  navigate("/message", {
                    state: {
                      url: uri,
                      sender: response.data.sender,
                      sender_last: response.data.sender_last,
                      sended: response.data.sended,
                      title: response.data.title,
                      message: response.data.message,
                      date: response.data.date,
                      programDate: response.data.programDate,
                      programDateV2: response.data.programDateV2,
                      photo: response.data.photo,
                      hash: response.data.hash,
                      mediaType: response.data.mediaType,
                      id: query.get("id"),
                    },
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          });
        }
      });
  }

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  const query = useQuery();

  function sendSmsCall() {
    console.log("DEBO ENVIAR");

    setChosenM("Call");
    axios
      .post(
        baseURL2,
        { id: query.get("id"), method: "Call" },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setSms(response.data);
      });
    setSend(false);
    console.log("sms sent");
    setResendDisabled(true);
    setTimeLeft(60);
    setCodeSent(true);
  }

  const sendSmsWha = () => {
    console.log("DEBO ENVIAR");

    setChosenM("Whats");
    axios
      .post(
        baseURL2,
        { id: query.get("id"), method: "Whatsapp" },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setSms(response.data);
      });
    setSend(false);
    console.log("sms sent");
    setResendDisabled(true);
    setTimeLeft(60);
    setCodeSent(true);
  };

  function sendSmsSMS() {
    console.log("DEBO ENVIAR");

    setChosenM("SMS");
    axios
      .post(
        baseURL2,
        { id: query.get("id"), method: "SMS" },
        {
          headers: headers,
        }
      )
      .then((response) => {
        setSms(response.data);
        setTimePassed(0);
      });
    console.log("sms sent");
    setResendDisabled(true);
    setTimeLeft(60);
    setCodeSent(true);
  }

  React.useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    } else if (timeLeft === 0) {
      setResendDisabled(false);
    }
  }, [timeLeft]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimePassed((prevTime) => prevTime + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    axios.post(baseURL, { id: query.get("id") }, { headers: headers })
      .then((response) => {
        var myString = response.data.toString().substring(0, 6) + "xxx" + response.data.toString().substring(10, response.data.toString().length);
        setPost(myString);
      });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="twoFactorAuthContainer">
      <div className="authCard">
        <div className="authCardContent">
          <img
            src="assets/img/authentication.png"
            alt=""
            className="authImage"
          />
          <h1 className="authTitle">Ingresa tu código de verificación</h1>
          <p className="authDescription">
            Ingresa el código de verificación enviado al {post} para acceder a tu mensaje
          </p>
          <div className="otpInputWrapper">
            <OtpInput onChange={validate} />
          </div>
          <div className="parent">
            <div className="smsButtonWrapper">
              {((chosenM === "Whats")) && <Button1 handleclick={sendSmsWha} style={{ minWidth: '200px' }}>{codeSent ? "Reenviar WhatsApp" : "Enviar WhatsApp"}</Button1>}
              {((chosenM === "SMSf")) && <Button1 handleclick={sendSmsSMS} style={{ minWidth: '200px' }}>Enviar SMS</Button1>}
              {((chosenM === "SMS")) && <Button1 handleclick={sendSmsSMS} style={{ minWidth: '200px' }}>Reenviar SMS</Button1>}
              {(chosenM === "Call") && <Button1 handleclick={sendSmsCall} style={{ minWidth: '200px' }}>Reenviar llamada</Button1>}
            </div>
          </div>
          <div className="verificationContainer">
            <p className="verificationText">Elije un método diferente</p>
            <div className="clickableTextContainer">
              {!(chosenM === "Whats") && (
                <div className="IconEmbedd">
                  <img className="icon" src={WhatsappIcon} alt="Whatsapp Icon" />
                  <span className="clickableText" onClick={sendSmsWha}>
                    Recibir por WhatsApp
                  </span>
                </div>
              )}
              {!(chosenM === "Call") && (
                <div className="IconEmbedd">
                  <img className="icon" src={CallIcon} alt="Call Icon" />
                  <span className="clickableText" onClick={sendSmsCall}>
                    Recibir llamada
                  </span>
                </div>
              )}
              {!(chosenM === "SMSf" || chosenM === "SMS") && (
                <div className="IconEmbedd">
                  <img className="icon" src={SmsIcon} alt="Sms Icon" />
                  <span className="clickableText" onClick={sendSmsSMS}>
                    Recibir SMS
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <toast position="top-center" />
    </div>
  );
};

export default TwoFactorAuth;
