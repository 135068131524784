import React from "react";
import Navbar from "../../components/Navbar/Navbar";

import { useLocation, useNavigate } from "react-router-dom";

const Expired = () => {

  const navigate = useNavigate();


  /*
  React.useEffect(() => {
    const handleBeforeUnload = () => {
      console.log("Running unload");
      navigate("/");
    };
  

    // handles when page is unloaded
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    // cleanup function handles when component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
  
      handleBeforeUnload();
    };
  }, []);

  */

  return (
    <section className="grid grid-cols-4 bg-white relative">
      <div
        className="min-h-screen bg-cover hidden lg:block lg:col-span-1 2xl:col-span-2"
        style={{
          backgroundImage: `url(assets/img/pastpostHome.webp)`,
        }}
      ></div>
      <div className="pt-3 lg:pl-28 lg:pt-6  lg:pr-20 col-span-4 lg:col-span-3 2xl:col-span-2">
        <Navbar />
        <div className="pt-5 px-3 lg:px-0 lg:pt-[102px]">
          <div className="bg-[#F8F8F8] mx-auto lg:mx-0  rounded-xl p-3 sm:p-5 md:pb-10 lg:pl-7 lg:py-14 max-w-[450px] md:max-w-[594px] ">
            <div className="max-w-lg">
              <img
                src="assets/img/warning.png"
                alt=""
                className="max-w-[50px] md:max-w-[70px]"
              />
              <h1 className=" pt-3 pb-2 lg:pt-5 lg:pb-5 text-xl md:text-3xl lg:text-4xl font-semibold text-headingBlack font-inter">
                Tu acceso ha expirado
              </h1>
              <p className="text-xs md:text-sm font-medium text-headingBlack ">
                "Regresa a tu correo y verifícate de nuevo para volver a
                ingresar"
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Expired;
